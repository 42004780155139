import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import CertonaZone from 'yoda-site-components/lib/components/CertonaZone/CertonaZone';
import { removeProductCartridgeSlot } from 'yoda-site-components/lib/actions/ProductCartridgeAction';
import { findMobileOS } from 'yoda-core-components/lib/helpers/Utils/Utils';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import get from 'lodash/get';
import getBreadCrumbInfo from '../../selectors/BreadCrumbs';
import { iOSScrollTimeoutForBlankPage } from '../../selectors/fromState';
import { getCategoryNID } from '../../utils';

export const Certona = (props) => {
    const { page } = props;

    const dispatch = useDispatch();

    const breadCrumbInfo = useSelector((state) => getBreadCrumbInfo(state));
    const deviceType = useSelector((state) => get(state, 'context?.deviceType', {}));
    const products = useSelector((state) => get(state, 'products', []));
    const organicData = useSelector((state) => get(state, 'commonData.gallery', false));
    const reRenderCertona = useSelector((state) =>
        get(state, 'renderCertona.reRenderCertona', false)
    );
    const enableRefreshCertona = useSelector((state) =>
        get(state, 'context.featureFlags.enableRefreshCertona', false)
    );
    const iOSScrollTimeoutForBlankPageData = useSelector((state) =>
        iOSScrollTimeoutForBlankPage(state)
    );

    const timerID = useRef(null);
    let cartridge1 = '';
    let cartridge2 = '';

    useEffect(() => {
        /* YODATOF-917 - on IOS 13.6.0 greater version few times when coming back from PDP
        page is going blank here dom is creating but not painting .. following code been added to fix this
    */
        /* istanbul ignore next */
        if (findMobileOS() === 'iOS' && !__SERVER__) {
            timerID.current = setInterval(() => {
                window.scroll();
            }, iOSScrollTimeoutForBlankPageData);
        }
        return () => {
            if (timerID.current) {
                clearInterval(timerID.current);
            }
        };
    }, []);

    useEffect(() => {
        cartridge1 && dispatch(removeProductCartridgeSlot(cartridge1));
    }, [cartridge1, organicData, enableRefreshCertona, products]);

    /* istanbul ignore next */
    const getOrganicSkus = () => {
        let skus = '';
        for (let i = 0; i < 10 && products[i]; i += 1) {
            skus += `${products[i].ppId};`;
        }
        return skus.slice(0, -1);
    };

    /* istanbul ignore next */
    const getRecZoneAttr = (page) => {
        let slotDetails;
        if (page.indexOf('galleryRecZones') !== -1) {
            slotDetails = {
                pageType: 'CATEGORY',
                attributes: {
                    recommendations: true,
                    categoryid: getCategoryNID(),
                    itemid: getOrganicSkus(),
                },
                loader: 'certona',
            };
            breadCrumbInfo.forEach((breadCrumb) => {
                if (breadCrumb.catId) {
                    slotDetails.attributes.categoryid = breadCrumb.catId;
                }
                if (breadCrumb.deptId) {
                    slotDetails.attributes.departmentid = breadCrumb.deptId;
                }
                if (breadCrumb.deptName) {
                    slotDetails.attributes.departmentname = breadCrumb.deptName;
                }
            });
            cartridge1 = reRenderCertona ? 'category4_rr' : 'category1_rr';
            cartridge2 = 'category2_rr';
            /* istanbul ignore else */
        } else if (page.indexOf('searchRecZones') !== -1) {
            slotDetails = {
                pageType: 'SEARCH',
                attributes: {
                    recommendations: true,
                    itemid: getOrganicSkus(),
                },
                loader: 'certona',
            };
            cartridge1 = reRenderCertona ? 'search4_rr' : 'search1_rr';
            cartridge2 = 'search2_rr';
        }
        return slotDetails;
    };

    /* istanbul ignore next */
    const getRefreshCertona = (page) => {
        switch (page) {
            case 'CATEGORY':
                return window.certonaExposeCategory;
            case 'SEARCH':
                return window.certonaExposeSearch;
            default:
                // do nothing;
                return null;
        }
    };

    const slotDetails = products?.length ? getRecZoneAttr(page) : '';
    const refreshCertona = reRenderCertona ? getRefreshCertona(slotDetails?.pageType) : null;

    return (
        <>
            {slotDetails && cartridge1 && cartridge2 ? (
                <div className={dt(['mb-6', 'mt-6'])}>
                    <CertonaZone
                        page={slotDetails.pageType}
                        slotDetails={{ ...slotDetails, slotId: cartridge1 }}
                        schemasToRender={cartridge1}
                        refreshCertona={refreshCertona}
                        showLoader={false}
                        disableLazyLoad={!deviceType.isDesktop}
                        wrapperClass={dt(['mb-4'])}
                    />
                    <CertonaZone
                        page={slotDetails.pageType}
                        slotDetails={{ ...slotDetails, slotId: cartridge2 }}
                        schemasToRender={cartridge2}
                        showLoader={false}
                        disableLazyLoad={!deviceType.isDesktop}
                    />
                </div>
            ) : null}
        </>
    );
};

export default Certona;

Certona.defaultProps = {
    breadCrumbInfo: [],
    deviceType: {
        isDesktop: false,
        isMobile: false,
        isTablet: false,
    },
    enableRefreshCertona: false,
    organicData: false,
    page: '',
    products: [],
    reRenderCertona: false,
};

Certona.propTypes = {
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    iOSScrollTimeoutForBlankPage: PropTypes.number.isRequired,
    breadCrumbInfo: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    deviceType: PropTypes.oneOfType([PropTypes.object]),
    enableRefreshCertona: PropTypes.bool,
    organicData: PropTypes.bool, // eslint-disable-line
    page: PropTypes.string,
    products: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    reRenderCertona: PropTypes.bool,
};
